@use 'common' as *;

body#top {
    >.wrap{
        width: 100%;
        overflow-x: hidden;
    }

    .c-article-list{
        .item{
            &:nth-child(n+3){
                @include SP{
                    display: none;
                }
            }
        }
    }
    .heading1{
        display: flex;
        align-items: center;
        justify-content: center;
        gap:35px;
        @include SP{
            gap:20px;
        }
        &:before,&:after{
            content:'';
            width: 100%;
            height: 1px;
            display: block;
            flex:1;
        }
        &.rg{
            &:before{
                background-color: $color-darkpink;
            }
            &:after{
                background-color: $color-lime;
            }
        }
        &.go{
            &:before{
                background-color: $color-green;
            }
            &:after{
                background-color: $color-orange;
            }
        }
        p{
            white-space: nowrap;
            text-align: center;
            flex:0;
            span{
                font-family: $inter;
                @include font-size(70);
                font-weight: 100;
                @include letter-spacing(150);
                @include SP{
                    @include font-size(26);
                    @include letter-spacing(100);
                }
            }
        }
    }
    .caption-cont{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap:45px;
        @include SP{
            justify-content: center;
        }
        &.col{
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap:35px;
            .caption-info{
                align-items: center;
            }
            .caption-text{
                text-align: center;
            }
        }
        .caption-info{
            display: flex;
            flex-direction: column;
            gap:16px;
            @include SP{
                align-items: center;
                gap:10px;
            }
            .jp{
                @include font-size(33);
                @include letter-spacing(350);
                font-weight: 500;
                @include SP{
                    @include font-size(20);
                    @include letter-spacing(250);
                }
            }
            .en{
                @include font-size(14);
                @include letter-spacing(100);
                font-family: $inter;
                font-weight: 600;
                @include SP{
                    @include font-size(11);
                    @include letter-spacing(20);
                }
            }
        }
        .caption-text{
            flex:1;
            @include font-size(16);
            line-height: 2em;
            margin-top: -8px;
            @include SP{
                display: none;
            }
        }
    }

    .more{
        width: 351px;
        height: 75px;
        background-image: url(../images/top/more.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-size(22);
        @include letter-spacing(350);
        font-weight: 500;
        margin: 80px auto 0;
        position: relative;
        @include SP{
            @include font-size(12);
            @include letter-spacing(200);
            margin-top: 16px;
            width: 170px;
            height: 63px;
        }
        &:after{
            content:'';
            width: 37px;
            height: 14px;
            background-image: url(../images/common/arr2.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            display: block;
            position: absolute;
            top:0;
            bottom: 0;
            right:20px;
            margin:auto;
            @include SP{
                width:18px;
                height: 9px;
                right:12px;
            }
        }
    }
    
    .header{
        background-color: $color-beige;
    }

    .mv{
        background-color: $color-beige;
        position: relative;
        background-color: #FAF7F1;
        width: calc(100% + 300px);
        border-bottom-left-radius: 50% 200px;
        border-bottom-right-radius: 50% 200px;
        margin-left: -150px;
        margin-right: -150px;
        padding-bottom: 45px;
        @include SP{
            width: calc(100% + 200px);
            margin-left: -100px;
            margin-right: -100px;
            border-bottom-left-radius: 50% 120px;
            border-bottom-right-radius: 50% 120px;
            padding:0 100px 1px;
        }
        .slider{
            .slide-arrow{
                width: 60px;
                height: 60px;
                border-top: 1px solid $color-base;
                border-right: 1px solid $color-base;
                position: absolute;
                top:240px;
                bottom:auto;
                left:0;
                right:0;
                margin: auto;
                z-index: 200;
                @include PC{
                    @include hover-opacity;
                }
                @include SP{
                    width: 20px;
                    height: 20px;
                    top:30vw;
                }
            }
            .prev-arrow{
                transform: rotate(-135deg);
                right:720px;
                @include SP{
                    right:auto;
                    left: 10px;
                }
            }
            .next-arrow{
                transform: rotate(45deg);
                left:720px;
                @include SP{
                    right:10px;
                    left: auto;
                }
            }
            .item{
                padding-top: 20px;
                width: 750px;
                transform: scale(.8);
                transition: transform .3s;
                transform-origin: center;
                outline:none;
                @include SP{
                    // padding-top: 10px;
                    // width: 230px;
                    // transform: scale(.7);
                    padding: 10px;
                    width: auto;
                    transform: scale(1);
                }
                &.slick-center{
                    transform: scale(1);
                }
                .c-edge{
                    width: 100%;
                    max-width: 100%;
                    aspect-ratio: 750 / 490;
                    figure{
                        background-size: cover;
                        background-position: center;
                        width: 100%;
                        height: 100%;
                    }
                }
                .info{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    position: relative;
                    margin-top: 32px;
                    @include SP{
                        margin-top: 10px;
                    }
                    &:before{
                        content:'';
                        width: 44px;
                        height: 1px;
                        display: block;
                        background-color: $color-base;
                        margin-right: 10px;
                        @include SP{
                            width: 18px;
                            margin-right: 6px;
                        }
                    }
                    time{
                        font-family: $inter;
                        font-style: italic;
                        @include font-size(20);
                        display: flex;
                        align-items: center;
                        margin-right: 0.8em;
                        padding-top: 2px;
                        @include SP{
                            @include font-size(11);
                            padding-top: 0;
                        }
                        &:after{
                            content:'';
                            width: 1px;
                            height: 20px;
                            display: block;
                            margin-left: 0.8em;
                            background-color: $color-base;
                            @include SP{
                                height:10px;
                            }
                        }
                    }
                    .cat{
                        @include font-size(20);
                        letter-spacing: 3px;
                        display: flex;
                        align-items: center;
                        line-height: 1em;
                        @include SP{
                            @include font-size(11);
                            letter-spacing: 2px;
                        }
                    }
                }
                .title{
                    margin-top: 12px;
                    @include font-size(24);
                    @include letter-spacing(100);
                    line-height: 1.81818em;
                    @include SP{
                        @include font-size(16);
                        margin-top: 7px;
                    }
                }
                .person{
                    @include font-size(18);
				    @include letter-spacing(100);
                    line-height: 2.1em;
                    @include SP{
                        @include font-size(12);
                    }
                }
            }
            /* dotsを四角くカスタマイズするCSS */
            .dots-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 50px;
                @include SP{
                    margin-top: 12px;
                }
            }

            .dots-wrap li {
                width: 60px;
                height: 5px;
                margin: 0 10px;
                background: gray;
                transition: .2s;
                cursor: pointer;
                @include SP{
                    width: 28px;
                    height: 4px;
                    margin: 0 4px;
                }
                &:nth-child(1){
                    background-color: $color-sky;
                }
                &:nth-child(2){
                    background-color: #C277AD;
                }
                &:nth-child(3){
                    background-color: $color-lime;
                }
                &:nth-child(4){
                    background-color: $color-orange;
                }
                &:nth-child(5){
                    background-color: $color-purple;
                }
                &:nth-child(6){
                    background-color: $color-blue;
                }
                &:nth-child(7){
                    background-color: $color-pink;
                }
            }

            .dots-wrap li:hover,
            .dots-wrap li.slick-active {
                height: 13px;
                @include SP{
                    height: 8px;
                }
            }
            .dots-wrap li button {
                display: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                outline: none;
                padding: 0;
                border: none;
                background-color: transparent;
            }
        }
        .scroll{
            display: block;
            position: absolute;
            top:96px;
            left:0;
            right:calc(750px + 75px);
            margin: auto;
            width: 10px;
            @include SP{
                display: none;
            }
        }
    }

    .interview{
        margin-top: 90px;
        position: relative;
        @include SP{
            margin-top: 30px;
        }
        .rainbow{
            width: calc(50% + 256px);
            height: 417px;
            background-image: url(../images/top/rainbow1.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 0 100px 0 0;
            opacity: 0.5;
            position: absolute;
            top:130px;
            left: 0px;
            z-index: -1;
            @include SP{
                width: calc(50% + 100px);
                border-radius: 0 35px 0 0;
                height: 140px;
                top:55px;
            }
        }
        .inner{
            .caption-cont{
                margin-top: 110px;
                @include SP{
                    margin-top: 53px;
                }
            }
            .post-list{
                margin-top: 60px;
                @include SP{
                    margin-top: 30px;
                }
            }
        }
    }

    .topics{
        margin-top: 126px;
        position: relative;
        @include SP{
            margin-top: 42px;
        }
        .rainbow{
            width: 662px;
            height: 479px;
            background-image: url(../images/top/rainbow2.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: absolute;
            top:-95px;
            left: 0px;
            right:0px;
            margin: auto;
            z-index: -1;
            @include SP{
                width: 290px;
                height: 210px;
                top:-50px;
                
            }
        }
        .inner{
            .caption-cont{
                margin-top: 50px;
                @include SP{
                    margin-top: 18px;
                }
            }
            .post-list{
                margin-top: 76px;
                @include SP{
                    margin-top: 30px;
                }
                .item{
                    &:nth-child(1){
                        &:after{
                            background-color: #C277AD;
                        }
                    }
                    &:nth-child(3){
                        &:after{
                            background-color: $color-blue;
                        }
                    }
                }
            }
        }
    }

    .read{
        margin-top: 100px;
        margin-bottom: 80px;
        @include SP{
            margin-top: 25px;
            padding-top: 20px;
            margin-bottom: 70px;
        }
    }

    .footer{
        @include SP {
            padding-bottom: calc(30px + 104px);    
        }
    }
    .footer-bottom{
        position:fixed;
        bottom:0;
        left: 0;
        width: 100%;
        z-index: 500;
    }

}
