@charset "UTF-8";
@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

/* argument */
/* site style */
/* [ opacity ]
-------------------------------------------------*/
/* [ display style ]
-------------------------------------------------*/
/* [ background-image ]
-------------------------------------------------*/
/* [ writing-mode ]
-------------------------------------------------*/
/* [ illustrator & photoshop letter spacing ]
-------------------------------------------------*/
/* [ easy breakpoint ]
-------------------------------------------------*/
/* [ easy transform ]
-------------------------------------------------*/
/* [ writing-mode ]
-------------------------------------------------*/
body#top > .wrap {
  width: 100%;
  overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
  body#top .c-article-list .item:nth-child(n+3) {
    display: none;
  }
}
body#top .heading1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
}
@media only screen and (max-width: 767px) {
  body#top .heading1 {
    gap: 20px;
  }
}
body#top .heading1:before, body#top .heading1:after {
  content: "";
  width: 100%;
  height: 1px;
  display: block;
  flex: 1;
}
body#top .heading1.rg:before {
  background-color: #E51373;
}
body#top .heading1.rg:after {
  background-color: #AACC03;
}
body#top .heading1.go:before {
  background-color: #009844;
}
body#top .heading1.go:after {
  background-color: #F29600;
}
body#top .heading1 p {
  white-space: nowrap;
  text-align: center;
  flex: 0;
}
body#top .heading1 p span {
  font-family: "Inter", sans-serif;
  font-size: 70px;
  font-size: 7rem;
  font-weight: 100;
  letter-spacing: 0.15em;
}
@media only screen and (max-width: 767px) {
  body#top .heading1 p span {
    font-size: 26px;
    font-size: 2.6rem;
    letter-spacing: 0.1em;
  }
}
body#top .caption-cont {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 45px;
}
@media only screen and (max-width: 767px) {
  body#top .caption-cont {
    justify-content: center;
  }
}
body#top .caption-cont.col {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;
}
body#top .caption-cont.col .caption-info {
  align-items: center;
}
body#top .caption-cont.col .caption-text {
  text-align: center;
}
body#top .caption-cont .caption-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media only screen and (max-width: 767px) {
  body#top .caption-cont .caption-info {
    align-items: center;
    gap: 10px;
  }
}
body#top .caption-cont .caption-info .jp {
  font-size: 33px;
  font-size: 3.3rem;
  letter-spacing: 0.35em;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  body#top .caption-cont .caption-info .jp {
    font-size: 20px;
    font-size: 2rem;
    letter-spacing: 0.25em;
  }
}
body#top .caption-cont .caption-info .en {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  body#top .caption-cont .caption-info .en {
    font-size: 11px;
    font-size: 1.1rem;
    letter-spacing: 0.02em;
  }
}
body#top .caption-cont .caption-text {
  flex: 1;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2em;
  margin-top: -8px;
}
@media only screen and (max-width: 767px) {
  body#top .caption-cont .caption-text {
    display: none;
  }
}
body#top .more {
  width: 351px;
  height: 75px;
  background-image: url(../images/top/more.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-size: 2.2rem;
  letter-spacing: 0.35em;
  font-weight: 500;
  margin: 80px auto 0;
  position: relative;
}
@media only screen and (max-width: 767px) {
  body#top .more {
    font-size: 12px;
    font-size: 1.2rem;
    letter-spacing: 0.2em;
    margin-top: 16px;
    width: 170px;
    height: 63px;
  }
}
body#top .more:after {
  content: "";
  width: 37px;
  height: 14px;
  background-image: url(../images/common/arr2.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  body#top .more:after {
    width: 18px;
    height: 9px;
    right: 12px;
  }
}
body#top .header {
  background-color: #FAF7F1;
}
body#top .mv {
  background-color: #FAF7F1;
  position: relative;
  background-color: #FAF7F1;
  width: calc(100% + 300px);
  border-bottom-left-radius: 50% 200px;
  border-bottom-right-radius: 50% 200px;
  margin-left: -150px;
  margin-right: -150px;
  padding-bottom: 45px;
}
@media only screen and (max-width: 767px) {
  body#top .mv {
    width: calc(100% + 200px);
    margin-left: -100px;
    margin-right: -100px;
    border-bottom-left-radius: 50% 120px;
    border-bottom-right-radius: 50% 120px;
    padding: 0 100px 1px;
  }
}
body#top .mv .slider {
  /* dotsを四角くカスタマイズするCSS */
}
body#top .mv .slider .slide-arrow {
  width: 60px;
  height: 60px;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
  position: absolute;
  top: 240px;
  bottom: auto;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 200;
}
@media only screen and (min-width: 768px) {
  body#top .mv .slider .slide-arrow {
    cursor: pointer;
    transition: all 0.3s;
  }
  body#top .mv .slider .slide-arrow:hover {
    opacity: 0.7;
  }
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .slide-arrow {
    width: 20px;
    height: 20px;
    top: 30vw;
  }
}
body#top .mv .slider .prev-arrow {
  transform: rotate(-135deg);
  right: 720px;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .prev-arrow {
    right: auto;
    left: 10px;
  }
}
body#top .mv .slider .next-arrow {
  transform: rotate(45deg);
  left: 720px;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .next-arrow {
    right: 10px;
    left: auto;
  }
}
body#top .mv .slider .item {
  padding-top: 20px;
  width: 750px;
  transform: scale(0.8);
  transition: transform 0.3s;
  transform-origin: center;
  outline: none;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .item {
    padding: 10px;
    width: auto;
    transform: scale(1);
  }
}
body#top .mv .slider .item.slick-center {
  transform: scale(1);
}
body#top .mv .slider .item .c-edge {
  width: 100%;
  max-width: 100%;
  aspect-ratio: 750/490;
}
body#top .mv .slider .item .c-edge figure {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
body#top .mv .slider .item .info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-top: 32px;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .item .info {
    margin-top: 10px;
  }
}
body#top .mv .slider .item .info:before {
  content: "";
  width: 44px;
  height: 1px;
  display: block;
  background-color: #000000;
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .item .info:before {
    width: 18px;
    margin-right: 6px;
  }
}
body#top .mv .slider .item .info time {
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-size: 20px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.8em;
  padding-top: 2px;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .item .info time {
    font-size: 11px;
    font-size: 1.1rem;
    padding-top: 0;
  }
}
body#top .mv .slider .item .info time:after {
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  margin-left: 0.8em;
  background-color: #000000;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .item .info time:after {
    height: 10px;
  }
}
body#top .mv .slider .item .info .cat {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  line-height: 1em;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .item .info .cat {
    font-size: 11px;
    font-size: 1.1rem;
    letter-spacing: 2px;
  }
}
body#top .mv .slider .item .title {
  margin-top: 12px;
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  line-height: 1.81818em;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .item .title {
    font-size: 16px;
    font-size: 1.6rem;
    margin-top: 7px;
  }
}
body#top .mv .slider .item .person {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  line-height: 2.1em;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .item .person {
    font-size: 12px;
    font-size: 1.2rem;
  }
}
body#top .mv .slider .dots-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .dots-wrap {
    margin-top: 12px;
  }
}
body#top .mv .slider .dots-wrap li {
  width: 60px;
  height: 5px;
  margin: 0 10px;
  background: gray;
  transition: 0.2s;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .dots-wrap li {
    width: 28px;
    height: 4px;
    margin: 0 4px;
  }
}
body#top .mv .slider .dots-wrap li:nth-child(1) {
  background-color: #00A0E9;
}
body#top .mv .slider .dots-wrap li:nth-child(2) {
  background-color: #C277AD;
}
body#top .mv .slider .dots-wrap li:nth-child(3) {
  background-color: #AACC03;
}
body#top .mv .slider .dots-wrap li:nth-child(4) {
  background-color: #F29600;
}
body#top .mv .slider .dots-wrap li:nth-child(5) {
  background-color: #7F1083;
}
body#top .mv .slider .dots-wrap li:nth-child(6) {
  background-color: #0068B6;
}
body#top .mv .slider .dots-wrap li:nth-child(7) {
  background-color: #EA6DA4;
}
body#top .mv .slider .dots-wrap li:hover,
body#top .mv .slider .dots-wrap li.slick-active {
  height: 13px;
}
@media only screen and (max-width: 767px) {
  body#top .mv .slider .dots-wrap li:hover,
  body#top .mv .slider .dots-wrap li.slick-active {
    height: 8px;
  }
}
body#top .mv .slider .dots-wrap li button {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  padding: 0;
  border: none;
  background-color: transparent;
}
body#top .mv .scroll {
  display: block;
  position: absolute;
  top: 96px;
  left: 0;
  right: 825px;
  margin: auto;
  width: 10px;
}
@media only screen and (max-width: 767px) {
  body#top .mv .scroll {
    display: none;
  }
}
body#top .interview {
  margin-top: 90px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  body#top .interview {
    margin-top: 30px;
  }
}
body#top .interview .rainbow {
  width: calc(50% + 256px);
  height: 417px;
  background-image: url(../images/top/rainbow1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0 100px 0 0;
  opacity: 0.5;
  position: absolute;
  top: 130px;
  left: 0px;
  z-index: -1;
}
@media only screen and (max-width: 767px) {
  body#top .interview .rainbow {
    width: calc(50% + 100px);
    border-radius: 0 35px 0 0;
    height: 140px;
    top: 55px;
  }
}
body#top .interview .inner .caption-cont {
  margin-top: 110px;
}
@media only screen and (max-width: 767px) {
  body#top .interview .inner .caption-cont {
    margin-top: 53px;
  }
}
body#top .interview .inner .post-list {
  margin-top: 60px;
}
@media only screen and (max-width: 767px) {
  body#top .interview .inner .post-list {
    margin-top: 30px;
  }
}
body#top .topics {
  margin-top: 126px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  body#top .topics {
    margin-top: 42px;
  }
}
body#top .topics .rainbow {
  width: 662px;
  height: 479px;
  background-image: url(../images/top/rainbow2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: -95px;
  left: 0px;
  right: 0px;
  margin: auto;
  z-index: -1;
}
@media only screen and (max-width: 767px) {
  body#top .topics .rainbow {
    width: 290px;
    height: 210px;
    top: -50px;
  }
}
body#top .topics .inner .caption-cont {
  margin-top: 50px;
}
@media only screen and (max-width: 767px) {
  body#top .topics .inner .caption-cont {
    margin-top: 18px;
  }
}
body#top .topics .inner .post-list {
  margin-top: 76px;
}
@media only screen and (max-width: 767px) {
  body#top .topics .inner .post-list {
    margin-top: 30px;
  }
}
body#top .topics .inner .post-list .item:nth-child(1):after {
  background-color: #C277AD;
}
body#top .topics .inner .post-list .item:nth-child(3):after {
  background-color: #0068B6;
}
body#top .read {
  margin-top: 100px;
  margin-bottom: 80px;
}
@media only screen and (max-width: 767px) {
  body#top .read {
    margin-top: 25px;
    padding-top: 20px;
    margin-bottom: 70px;
  }
}
@media only screen and (max-width: 767px) {
  body#top .footer {
    padding-bottom: 134px;
  }
}
body#top .footer-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 500;
}